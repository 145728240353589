/* scripts portale */
   $(document).on('keyup',function(evt) {
       if (evt.keyCode == 27 && $('body').hasClass('menu-opened')) {
         $('#chiudimenu').click();
       }
       if (evt.keyCode == 27 && $('body').hasClass('menu-opened2')) {
         $('#chiudimenu2').click();
       }       
   });

   $(document).ready(function() {
      
     /*  if($('#tilescontainer').length > 0){
         $('#tilescontainer').imagesLoaded( function() {
            $('#tilescontainer').masonry({
               itemSelector: '.tile',
               columnWidth: '.tile'
            });
         });
      } */
         
      if($('.sezioni-pannelli').length > 0){           
         $('.sezioni-pannelli').imagesLoaded( function() {
            $('.sezioni-pannelli').masonry({
               itemSelector: '.tile',
               columnWidth: '.tile'
            });
         });
      }
         $("input:checkbox").click(function() {
             if ($(this).is(":checked")) {
                 var group = "input:checkbox[name='" + $(this).attr("name") + "']";
                 $(group).prop("checked", false);
                 $(this).prop("checked", true);
             } else {
                 $(this).prop("checked", false);
             }
         });

      $('.menu-btn').click(function() {
         $('body,html').animate({scrollTop:0},200);
         $('.sidebar-offcanvas').toggleClass('active', 1000);
      });
      $('#chiudimenu').on('click',function(e){
         e.preventDefault();
         $('.sidebar-offcanvas').toggleClass('active', 1000);
      });

      $('.menu-btn-right').click(function() {
         $('body,html').animate({scrollTop:0},200);
         $('.sidebar-offcanvas-right').toggleClass('active', 1000);
      });
      
      $('#chiudimenu2').on('click',function(e){
         e.preventDefault();
         $('.sidebar-offcanvas-right').toggleClass('active', 1000);
      });

      
      $('.collapse').on('show.bs.collapse', function (e) {         
          $('.collapse').not(e.target).removeClass('in').prev().addClass('collapsed');
      });

      $("#topcontrol").click(function() {
         $('body,html').animate({scrollTop:0},500);
      });

      $('#key').blur(function(e){
         if($(this).val() != '' && $(this).val().length > 1){
            $(this).parentsUntil('.form').parent().submit();
         }         
      }); 
    
      



   $(window).load(function(){
      jQuery('.sezioni-pannelli').resize(); 
   });
   

   
$(window).on("scroll touchmove", function () {
  $('#mainheader').toggleClass('ridotto', $(document).scrollTop() > 32);
  $('body').toggleClass('ridotto', $(document).scrollTop() > 32);
  $('#topcontrol').toggleClass('visualizza', $(document).scrollTop() > 200);
  
  if (($(document).scrollTop() > 32)){
    $('#headerDesc').hide();
    $('#headerMinimale').show();
  }
  else{
    $('#headerDesc').show();
    $('#headerMinimale').show(); 
  }

});


$(function() {

  $('#select_regioni').on('change', function() {
    var url = $(this).val();
    if (url) { 
      window.location = url; 
    }
    return false;
  });
});

});